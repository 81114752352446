// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem, baseVariables } from '@marriott/mi-ui-library';
import { PAGES } from '../../modules/utils/constants';

export const StyledGolfServices = styled.div`
  border-radius: ${toRem(16)};

  img {
    width: 100%;
    aspect-ratio: 1/1;
  }

  @media ${baseVariables.mediaQuery.md} {
    img {
      aspect-ratio: 3/1;
    }
  }

  .services-container {
    border-radius: ${toRem(16)};
    &__image-section {
      img {
        border-radius: ${toRem(16)} ${toRem(16)} 0 0;
      }
    }

    &__overview-section {
      .t-overline-normal,
      .t-subtitle-xl {
        margin-bottom: ${toRem(12)};
      }

      max-width: none;
      padding: ${toRem(16)};

      @media ${baseVariables.mediaQuery.md} {
        padding: ${toRem(24)};
      }

      @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
        max-width: 60%;
        padding: unset;
      }
    }

    &__step-section {
      row-gap: ${toRem(16)};

      @media ${baseVariables.mediaQuery.md},
        @media ${baseVariables.mediaQuery.md},
        @media ${baseVariables.mediaQuery.md} {
        column-gap: ${toRem(16)};
      }
    }

    &__separator {
      height: ${toRem(1)};
      border-top: 1px solid ${baseVariables.color['neutral30']};
    }
  }

  .summary-title {
    max-width: 90%;
  }
`;

interface StyledServicesProp {
  currentPage?: string;
}

export const StyledGolfServiesOffered = styled.div<StyledServicesProp>`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(1, 1fr);
  gap: ${toRem(16)};
  row-gap: ${toRem(16)};
  border-top: 1px solid ${baseVariables.color['neutral30']};

  @media ${baseVariables.mediaQuery.md} {
    row-gap: ${toRem(48)};
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    row-gap: ${toRem(48)};
    grid-template-columns: ${props =>
      props.currentPage === PAGES.GOLF_PROGRAMS_PAGE ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
  }

  .service-container {
    word-break: break-word;
  }

  .services-offered-icon {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: ${toRem(12)};
  }
`;
