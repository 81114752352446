/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react';

type PageParamsContextType = {
  userId?: string;
  currentPage?: string;
  backgroundColor?: string;
  outletId?: string | number;
  marsha?: string;
  fallbackDescription?: string;
  fallbackHeroImgPath?: string;
  outletDataResponse?: any;
  recommendationData?: any;
  heroBannerImages?: any;
  currentLocale?: string;
  headersData?: any;
  campaignParams?: any;
  campaignId?: any;
  campaignDataResponse?: any;
  campaignDataError?: boolean;
};
export const PageParamsContext = createContext<PageParamsContextType>({});
