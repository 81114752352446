import { EditableComponent } from '@adobe/aem-react-editable-components';
import { Heading, Types, TextComponent, size } from '@marriott/mi-ui-library';
import { StyledGolfEventScheduler, StyledEventsContainer } from './GolfEventScheduler.styles';
import clsx from 'clsx';
import { EventList, GolfEventSchedulerProps } from './GolfEventScheduler.types';
import { useEffect, useState } from 'react';
import { useGetBreakpoint } from '../../modules/utils/helpers';
import { TRACKING_CONSTANT } from '../../modules/utils/constants/constants';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const GolfEventScheduler = (props: GolfEventSchedulerProps) => {
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const { GOLF_EVENT_SCHEDULER, EXTERNAL_LINK } = TRACKING_CONSTANT;

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getHeader = (event: EventList) => {
    const click_track_value = `${GOLF_EVENT_SCHEDULER}|${event?.propertyName?.replaceAll(' ', '-')}|${EXTERNAL_LINK}`;

    return event?.propertyUrl
      ? `<a class=${'custom_click_track'} href=${event?.propertyUrl}
            data-custom_click_track_value=${click_track_value}>${event?.propertyName}</a>`
      : `<p>${event?.propertyName}</p>`;
  };

  return props?.eventDetailsList && props?.eventDetailsList?.length > 0 ? (
    <StyledGolfEventScheduler
      className={clsx(
        {
          'm-container-fullbleed': props?.styleclass?.includes('fullbleed'),
        },
        props?.styleclass
      )}
    >
      <div className={clsx('container', !isMobileViewPort ? 'p-0' : '')}>
        <Heading
          titleText={props?.title}
          variation={Types.headingType.title}
          fontSize={Types.size.small}
          customClass="text-center pb-4 m-0"
        />

        <StyledEventsContainer className="py-5">
          {props?.eventDetailsList?.map((event: EventList, index: number) => {
            return (
              <TextComponent
                contentAlignment="left"
                eyebrow={event?.eventDate}
                subHeader={getHeader(event)}
                subHeaderFontSize={size.large}
                componentId={event?.propertyName || `event${index}`}
                styleclass={clsx('styled-events', isMobileViewPort ? 'p-0' : '')}
                descriptionText={event?.locationText}
                showLessDesc={false}
                descriptionFontSize={size.extraSmall}
              />
            );
          })}
        </StyledEventsContainer>
      </div>
    </StyledGolfEventScheduler>
  ) : null;
};

export const GolfEventSchedulerConfig = {
  emptyLabel: 'eventschedulecomponent',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/golf/eventschedulecomponent`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GolfEventSchedulerEditable = (props: any) => (
  <EditableComponent config={GolfEventSchedulerConfig} {...props}>
    <GolfEventScheduler {...props} />
  </EditableComponent>
);
