import { EditableComponent } from '@adobe/aem-react-editable-components';
import { StyledGolfServices, StyledGolfServiesOffered } from './GolfServicesContainer.styles';
import { Heading, Text, Types, Image, Icon, Button } from '@marriott/mi-ui-library';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useGetBreakpoint } from '../../modules/utils/helpers';
import { GolfServicesContainerProps, Services } from './GolfServicesContainer.types';
import { PageParamsContext } from '../../modules/context/PageContext';
import { TRACKING_CONSTANT } from '../../modules/utils/constants/constants';

const { NEXT_PUBLIC_AEM_SITE } = process.env;

export const GolfServicesContainer = (props: GolfServicesContainerProps) => {
  const { currentPage } = useContext(PageParamsContext);
  const [isMobileViewPort, setIsMobileViewPort] = useState(useGetBreakpoint() === 'mobile');
  const { EXTERNAL_LINK } = TRACKING_CONSTANT;

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      setIsMobileViewPort(useGetBreakpoint() === 'mobile');
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StyledGolfServices
      className={clsx(props?.styleclass, {
        'm-container-fullbleed': props?.styleclass?.includes('fullbleed'),
      })}
    >
      <div className="services-container">
        {props?.dynamicMedia && props?.dynamicMedia?.assetPath && (
          <div className="services-container__image-section">
            <Image
              title=""
              defaultImageURL={props?.dynamicMedia?.assetPath}
              customClass=""
              loading="eager"
              altText={props?.dynamicMedia?.altText}
              renditions={props?.dynamicMedia?.renditions}
              dynamic={props?.dynamicMedia?.dynamic}
            />
          </div>
        )}
        {(props?.title || props?.description) && (
          <div
            className={clsx('services-container__overview-section m-auto d-flex flex-column text-center py-3 py-md-4')}
          >
            {props?.title && (
              <Heading
                variation={Types.headingType.subtitle}
                titleText={props?.title}
                fontSize={Types.size.extraLarge}
                element={Types.tags.span}
              />
            )}
            {props?.description && (
              <Text
                copyText={props?.description}
                fontSize={Types.size.medium}
                element={Types.tags.span}
                customClass="t-font-m"
              />
            )}
          </div>
        )}
        {props?.servicesOffered && props?.servicesOffered?.length > 0 && (
          <StyledGolfServiesOffered
            className={clsx(isMobileViewPort ? 'py-3' : 'py-4', 'my-0 px-0')}
            currentPage={currentPage}
          >
            {props?.servicesOffered?.map((service: Services) => {
              return (
                <div className="d-flex flex-column service-container">
                  <Icon
                    iconClass={`${service?.serviceIconPath} services-offered-icon`}
                    ariaLabel={service?.serviceIconPath}
                  />

                  <Heading
                    variation={Types.headingType.subtitle}
                    titleText={service?.serviceTitle}
                    fontSize={Types.size.medium}
                    element={Types.tags.span}
                    customClass="mb-1"
                  />
                  <Text
                    copyText={service?.serviceDescription}
                    fontSize={Types.size.small}
                    element={Types.tags.span}
                    customClass="t-font-alt-s"
                  />
                </div>
              );
            })}
          </StyledGolfServiesOffered>
        )}
        {(props?.bottomDescription || (props?.golfCtaLabel && props?.golfCtaUrl)) && (
          <div className="pt-4 pb-5 d-flex flex-column align-items-center">
            {props?.bottomDescription && (
              <Text
                copyText={props?.bottomDescription}
                fontSize={Types.size.small}
                element={Types.tags.span}
                customClass={'t-font-s mb-3 summary-title text-center'}
              />
            )}
            {props?.golfCtaLabel && props?.golfCtaUrl && (
              <Button
                className="m-button-primary m-button-s custom_click_track"
                buttonCopy={props?.golfCtaLabel}
                href={props?.golfCtaUrl}
                isLink={true}
                target={props?.openInNewTab === true ? '_blank' : ''}
                custom_click_track_value={`${props?.trackingProperties?.location}|${props?.trackingProperties?.trackingDescription}|${EXTERNAL_LINK}`}
                trackingProperties={props?.trackingProperties}
              ></Button>
            )}
          </div>
        )}
      </div>
    </StyledGolfServices>
  );
};

export const GolfServicesContainerConfig = {
  emptyLabel: 'golfservices',
  isEmpty: false,
  resourceType: `${NEXT_PUBLIC_AEM_SITE}/components/content/golf/golfservices`,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const GolfServicesEditable = (props: any) => (
  <EditableComponent config={GolfServicesContainerConfig} {...props}>
    <GolfServicesContainer {...props} />
  </EditableComponent>
);
