import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';

export const StyledGolfEventScheduler = styled.div`
  padding-top: ${toRem(48)};

  @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    padding-top: ${toRem(64)};
    padding-bottom: ${toRem(32)};
  }
`;

export const StyledEventsContainer = styled.div`
  background: inherit;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(1, 1fr);
  gap: ${toRem(24)};
  row-gap: ${toRem(24)};

  @media ${baseVariables.mediaQuery.md}, @media ${baseVariables.mediaQuery.lg}, @media ${baseVariables.mediaQuery.xl} {
    grid-template-columns: repeat(2, 1fr);
  }

  .styled-events {
    word-break: break-word;

    .t-overline-alt-normal,
    .t-subtitle-l {
      margin-bottom: ${toRem(4)} !important;
    }

    .t-overline-alt-normal {
      color: ${baseVariables.color.merch20};
    }

    .t-font-xs,
    .t-font-xs > div {
      margin-bottom: 0 !important;
    }
  }
`;
